import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {
  content: any
  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient) { }
    
  async ngOnInit() {
    await this.authService.completeAuthentication();
    const token = this.authService.getAccessTokenValue();
    const returnUrl = localStorage.getItem('returnUrl');
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
    }

  
  }

}
