import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { MENUACTIONROLEAPI } from '../common/constants/constant';
import { Router } from '@angular/router';
const apiKSFBaseUrl = environment.apiKSFBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiKSFServer = environment.apiKSFBase;
const apiUploadS3Url = environment.apiUploadS3Base;
@Injectable()
export class ApiService {
  pathUrl: string = ''
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService) {
   
     }
  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
      'X-Prod-Type': 'homevillage',
    })
  };
  headers = new HttpHeaders()
  apiTesst(): Observable<any> {
    return this.http
      .get<any>(`https://printer.sunshinegroup.vn:8080/api/printer`);
  }

  // Mẫu thông báo UInvTemplateNotify

  getTemplateNotifyPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetTemplateNotifyPage?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  getTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetTemplateNotifyInfo?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  setTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/owner/SetTemplateNotifyInfo` , queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  delTemplateNotifyInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/DelTemplateNotifyInfo?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  getColumnObjectByLink(url): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}` + url, this.options);
  }
 

  // UInvWork

  getoptions(menuId= '', actionId= ''): any {
    return {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'Content-Type': 'application/json',
        'X-Prod-Type': 'homevillage',
        // 'X-Menu-Id': menuId || '',
        // 'X-Action-Id': actionId || ''
      })
    }
  }

  getParameterPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetParameterPage?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  getCfgCommonPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetCfgCommonPage?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }

  getCfgCommon(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetCfgCommon?` + queryParams
      , this.getoptions(MENUACTIONROLEAPI.GetParameterPage.api, MENUACTIONROLEAPI.GetParameterPage.menu));
  }
 

  getInvParameter(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/getInvParameter?` + queryParams
    , this.getoptions('', ''));
  }

  setCfgCommon(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetCfgCommon`, queryParams, this.getoptions());
  }

  setInvParameter(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/setInvParameter`, queryParams, this.getoptions());
  }

  getCustObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.getoptions());
  }

  getWorkflowPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetWorkflowPage.api, MENUACTIONROLEAPI.GetWorkflowPage.menu));
  }

  getWorkflowInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowInfo?` + queryParams, this.getoptions());
  }

  setWorkSubmit(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkSubmit`, queryParams, this.getoptions());
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkApprove`, queryParams, this.getoptions());
  }

  getIntroPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetIntroPage.api, MENUACTIONROLEAPI.GetIntroPage.menu));
  }

  getIntroInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroInfo?` + queryParams, this.getoptions());
  }

  getHolidayPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetHolidayPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetHolidayPage.api, MENUACTIONROLEAPI.GetHolidayPage.menu));
  }

  delHoliday(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/work/DelHoliday?` + queryParams, this.getoptions());
  }

  setHolidayAdd(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/SetHolidayAdd`, queryParams, this.getoptions());
  }

  setHolidayCreate(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/SetHolidayCreate`, queryParams, this.getoptions());
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.getoptions());
  }

  delIntroInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/work/DelIntroInfo?` + queryParams, this.getoptions());
  }


  // UInvProduct

  getProductPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetProductPage.api, MENUACTIONROLEAPI.GetProductPage.menu));
  }

  getProducInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProducInfo?` + queryParams, this.getoptions());
  }

  getProdFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProdFilter`, this.getoptions());
  }

  getProductList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductList?` + queryParams, this.getoptions());
  }

  getProductOrderPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductOrderPage?` + queryParams, this.getoptions());
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProjectList?` + queryParams, this.getoptions());
  }

  delProduct(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/DelProduct?` + queryParams, this.getoptions());
  }

  setProductCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/SetProductCancel?` + queryParams, this.getoptions());
  }

  setProductInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/product/SetProductInfo`, queryParams, this.getoptions());
  }

  setProductSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/product/SetProductSubmit`, queryParams, this.getoptions());
  }

  //UInvOwner

  getOwnerList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerList`, this.getoptions());
  }

  getOwnerPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetOwnerPage.api, MENUACTIONROLEAPI.GetOwnerPage.menu));
  }

  getOwnerInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerInfo?` + queryParams, this.getoptions());
  }

  setOwnerInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerInfo`, params, this.getoptions());
  }

  setOwnerStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerStatus`, params, this.getoptions());
  }

  delOwnerInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/owner/DelOwnerInfo?` + queryParams, this.getoptions());
  }

  // UInvOpen

  getOpenPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetOpenPage.api, MENUACTIONROLEAPI.GetOpenPage.menu));
  }

  getOpenFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenFilter`, this.getoptions());
  }

  getOpenInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenInfo?` + queryParams, this.getoptions());
  }

  setOpenInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetOpenInfo`, params, this.getoptions());
  }

  delOpenInfo(params): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenInfo?` + params, this.getoptions());
  }

  setOpenStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenStatus`, params, this.getoptions());
  }

  getOpenDetail(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenDetail?` + queryParams, this.getoptions());
  }

  getOpenPolicyPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPolicyPage?` + queryParams, this.getoptions());
  }

  getOpenVoucherPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openvoucher/GetOpenVoucherPage?` + queryParams, this.getoptions());
  }

  getOpenVoucher(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openvoucher/GetOpenVoucher?` + queryParams, this.getoptions());
  }

  delOpenVoucher2(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openvoucher/DelOpenVoucher?` + queryParams, this.getoptions());
  }

  setOpenVoucher(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openvoucher/SetOpenVoucher`, queryParams, this.getoptions());
  }

  getOpenConvertPageNew(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openconvert/GetOpenConvertPage?` + queryParams, this.getoptions());
  }

  getOpenConvert(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openconvert/GetOpenConvert?` + queryParams, this.getoptions());
  }

  setOpenConvert(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openconvert/SetOpenConvert`, queryParams, this.getoptions());
  }

  getOpenSchemePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/GetOpenSchemePage?` + queryParams, this.getoptions());
  }

  getOpenScheme(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/GetOpenScheme?` + queryParams, this.getoptions());
  }

  setOrderContInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/ordercont/SetOrderContInfo`, queryParams, this.getoptions());
  }

  getOpenRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openroom/GetOpenRoomPage?` + queryParams, this.getoptions());
  }

  getOpenRoomSalePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openroom/GetOpenRoomSalePage?` + queryParams, this.getoptions());
  }

  // getOpenScheme(queryParams): Observable<any> {
  //   return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenScheme?` + queryParams, this.getoptions());
  // }

  getOpenTenor(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openscheme/getOpenTenor?` + queryParams, this.getoptions());
  }

  delOpenTenor(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openscheme/DelOpenTenor?` + queryParams, this.getoptions());
  }

  delOpenScheme(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openscheme/DelOpenScheme?` + queryParams, this.options);
  }

  delOpenDiscount(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openconvert/DelOpenDiscount?` + queryParams, this.getoptions());
  }

  delOpenVoucher(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenVoucher?` + queryParams, this.getoptions());
  }

  delOpenConvert(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openconvert/DelOpenConvert?` + queryParams, this.options);
  }

  delOpenPolicy(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenPolicy?` + queryParams, this.getoptions());
  }

  setOpenTenor(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openscheme/SetOpenTenor`, queryParams, this.getoptions());
  }

  setOpenScheme(urlApiSave, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openscheme/${urlApiSave}`, queryParams, this.getoptions());
  }

  setOpenconvert(urlApiSave, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openconvert/${urlApiSave}`, queryParams, this.getoptions());
  }

  getOpenList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenList?` + queryParams, this.getoptions());
  }

  getConsultantReferral(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/orderagent/GetConsultantReferral?` + queryParams, this.getoptions());
  }

  getConsultantPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/orderagent/GetConsultantPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetConsultantPage.api, MENUACTIONROLEAPI.GetConsultantPage.menu));
  }

  setOpenSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenSubmit`, queryParams, this.getoptions());
  }

  getOpenVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenVoucherList?${queryParams}`, this.getoptions());
  }

  getOpenSchemes(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenSchemes?${queryParams}`, this.getoptions());
  }

  // UInvConfig 

  getFormViewPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetFormViewPage?` + queryParams, this.getoptions());
  }

  getGroupInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetGroupInfo?` + queryParams, this.getoptions());
  }

  getGridViewPage(url, queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.getoptions());
  }

  setFormViewInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetFormViewInfo`, queryParams, this.getoptions());
  }
  
  setGroupInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetGroupInfo`, queryParams, this.getoptions());
  }

  setGridViewInfo(url, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/${url}`, queryParams, this.getoptions());
  }

  delFormViewInfo(url, queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.getoptions());
  }

  delGridViewInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/config/DelGridViewInfo?` + queryParams, this.getoptions());
  }



  //UInvCooperatorShow

  getCooperatorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorPage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetCooperatorPage.api, MENUACTIONROLEAPI.GetCooperatorPage.menu));
  }

  getCooperatorInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorInfo?` + queryParams, this.getoptions());
  }

  delCooperatorInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/cooperator/DelCooperatorInfo?` + queryParams, this.getoptions());
  }

  setCooperatorInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorInfo`, queryParams, this.getoptions());
  }

  setCooperatorStatus(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorStatus`, queryParams, this.getoptions());
  }

  getCooperatorList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorList`, this.getoptions());
  }

  // UInvTransaction

  getTransFilter(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransFilter`, this.getoptions());
  }

  setTransactionApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionApprove`, queryParams, this.getoptions());
  }

  setNoPayApprovedOfFinal(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/final/SetNoPayApprovedOfFinal`, queryParams, this.getoptions());
  }

  setPayOnHalfApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetPayOnHalfApprove`, queryParams, this.getoptions());
  }

  setTransactionInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionInfo`, queryParams, this.getoptions());
  }

  setOrderPaymentApprove(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFBaseUrl}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderPaymentApprove`, queryParams, this.getoptions());
  }

  setTransactionNotify(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionNotify`, queryParams, this.getoptions());
  }

  getTransactionPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetTransactionPage.api, MENUACTIONROLEAPI.GetTransactionPage.menu));
  }

  getTransactionInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionInfo?` + queryParams, this.getoptions());
  }

  getTransBankPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetTransBankPage.api, MENUACTIONROLEAPI.GetTransBankPage.menu));
  }

  getTransBankPayonPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPayonPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetTransBankPayonPage.api, MENUACTIONROLEAPI.GetTransBankPayonPage.menu));
  }

  getTransAccounts(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransAccounts`
    , this.getoptions(MENUACTIONROLEAPI.GetTransAccounts.api, MENUACTIONROLEAPI.GetTransAccounts.menu));
  }

  getTransBankAccountPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankAccountPage`, queryParams, this.getoptions());
  }

  delTransaction(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/DelTransaction?` + queryParams, this.getoptions());
  }

  setTransactionCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionCancel?` + queryParams, this.getoptions());
  }

  // UInvExchange

  setExchange(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchange`, queryParams, this.getoptions());
  }

  setExchangeApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeApprove`, queryParams, this.getoptions());
  }

  setExchangeCancel(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeCancel`, queryParams, this.getoptions());
  }

  getExchangePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangePage?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetExchangePage.api, MENUACTIONROLEAPI.GetExchangePage.menu));
  }

  getExchangeInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangeInfo?` + queryParams, this.getoptions());
  }

  delExchange(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/exchange/DelExchange?` + queryParams, this.getoptions());
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/webmanager/ClientMenuGetListByUserId?` +
      `userId=${userId}&webId=${webId}`, options)
  }


  //UInvOrderBook

  getOrderBookPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookPage?${queryParams}`, this.getoptions());
  }

  getOrderFilter(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderFilter?${queryParams}`, this.getoptions());
  }

  getOrderLinePage(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderLinePage?${queryParams}`, this.getoptions());
  }

  getOrderRoomPage(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderRoomPage?${queryParams}`, this.getoptions());
  }

  getOrderMetaPage(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderMetaPage?${queryParams}`, this.getoptions());
  }

  getOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookInfo?${queryParams}`, this.getoptions());
  }

  setOrderBookDraft(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookDraft`, queryParams, this.getoptions());
  }

  setOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookInfo`, queryParams, this.getoptions());
  }

  delOrderBook(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/book/DelOrderBook?${queryParams}`, this.getoptions());
  }

  setOrderBookStatus(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookStatus`, params, this.getoptions());
  }




  //UInvOrder
  setOrderLockNotify(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderLockNotify`, params, this.getoptions());
  }

  setOrderMetaUpload(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderMetaUpload`, params, this.getoptions());
  }

  setContract(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetContract`, params, this.getoptions());
  }

  setOrderUpOnline(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderUpOnline`, params, this.getoptions());
  }

  setOrderVoucher(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordervoucher/setOrderVoucher`, params, this.getoptions());
  }

  setSiptOrderApproveReg(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderApproveReg`, params, this.getoptions());
  }

  setOrderHold(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderHold`, params, this.getoptions());
  }

  getContractFile(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetContractFile?${queryParams}`, this.getoptions());
  }

  getOrderHoldInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderhold/GetOrderHoldInfo?${queryParams}`, this.getoptions());
  }

  getOrderContInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/getOrderContInfo?${queryParams}`, this.getoptions());
  }

  getOrderFinance(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderFinance?${queryParams}`, this.getoptions());
  }

  delOrderVoucher(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/ordervoucher/DelOrderVoucher?${queryParams}`, this.getoptions());
  }

  getOrderVoucher(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucher?${queryParams}`, this.getoptions());
  }

  getOrderVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucherPage?${queryParams}`, this.getoptions());
  }

  delOrderMetaUpload(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.delete(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/DelOrderMetaUpload?${queryParams}`, this.getoptions());
  }

  getOrderPage(queryParams: any, branch_type): Observable<any> {
    this.pathUrl = this.router.url
    if(branch_type === 0) {
      return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderPage?${queryParams}`
      , this.getoptions(MENUACTIONROLEAPI.GetOrderPage.api, MENUACTIONROLEAPI.GetOrderPage.menu));
    }else if(branch_type === 1) {
      return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderPage?${queryParams}`
      , this.getoptions(MENUACTIONROLEAPI.GetOrderPage1.api, MENUACTIONROLEAPI.GetOrderPage1.menu));
    }else if(branch_type === 2) {
      return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderPage?${queryParams}`
      , this.getoptions(MENUACTIONROLEAPI.GetOrderPage2.api, MENUACTIONROLEAPI.GetOrderPage2.menu));
    }else {
      return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderPage?${queryParams}`
        , this.getoptions(MENUACTIONROLEAPI.GetOrderPage3.api, MENUACTIONROLEAPI.GetOrderPage3.menu));
    }
    
  }

  getOrderContPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetOrderContPage.api, MENUACTIONROLEAPI.GetOrderContPage.menu));
  }

  getVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetVoucherPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetVoucherPage.api, MENUACTIONROLEAPI.GetVoucherPage.menu));
  }

  getVoucherFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetVoucherFilter`, this.options);
  }
  
  getOrderContDelive(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContDelive?${queryParams}`, this.getoptions());
  }

  setOrderBook(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderBook`, queryParams, this.getoptions());
  }

  setOrderContReceive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContReceive`, queryParams, this.getoptions());
  }

  setOrderContDelive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContDelive`, queryParams, this.getoptions());
  }

  setOrderUpgate(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderUpgate`, queryParams, this.getoptions());
  }

  getOrderInfo(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderInfo?${queryParams}`, this.getoptions());
  }

  getOrderFlow(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderFlow?${queryParams}`, this.getoptions());
  }

  setOrderNotify(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/setOrderNotify`, queryParams, this.getoptions());
  }

  setOrderRelease(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderRelease`, queryParams, this.getoptions());
  }

  setOrderPaymentRollback(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderPaymentRollback`, queryParams, this.getoptions());
  }

  setOrderNotifyPay(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderNotifyPay`, queryParams, this.getoptions());
  }

  setOrderCancel(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderCancel`, queryParams, this.options);
  }

  setVoucherClean(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherClean`, queryParams, this.getoptions());
  }

  setVoucherPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherPlan`, queryParams, this.getoptions());
  }

  setVoucherReceived(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherReceived`, queryParams, this.getoptions());
  }

  setPayApproveOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayApproveOfVoucher`, queryParams, this.getoptions());
  }

  setPayOnHalfOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayOnHalfOfVoucher`, queryParams, this.getoptions());
  }

  setBondLiqRequest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderconvert/SetBondLiqRequest`, queryParams, this.options);
  }

  delOrder(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.delete(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/DelOrder?${queryParams}`, this.getoptions());
  }

  //UInvInterest

  getInterestFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/interest/GetInterestFilter`, this.getoptions());
  }

  getOrderInterestPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/GetOrderInterestPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetOrderInterestPage.api, MENUACTIONROLEAPI.GetOrderInterestPage.menu));
  }

  setOrderInterestPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetOrderInterestPlan`, queryParams, this.getoptions());
  }

  setPayOnHalfOfInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetPayOnHalfOfInterest`, queryParams, this.getoptions());
  }

  //UInvWithdrawal

  getWithdrawalFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetWithdrawalFilter`, this.getoptions());
  }

  getTransBankFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/transaction/GetTransBankFilter`, this.getoptions());
  }

  getTransPayonFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/transaction/GetTransPayonFilter`, this.getoptions());
  }


  getOrderWithdrawalPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetOrderWithdrawalPage.api, MENUACTIONROLEAPI.GetOrderWithdrawalPage.menu));
  }

  getOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalInfo?${queryParams}`, this.getoptions());
  }

  delWithdrawal(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/withdrawal/DelWithdrawal?${queryParams}`, this.getoptions());
  }

  setOrderWithdrawalDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalDraft`, queryParams, this.getoptions());
  }

  setOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalInfo`, queryParams, this.getoptions());
  }

  setOrderWithdrawalSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalSet`, queryParams, this.getoptions());
  }

  //UInvLiquidation

  getLiquidationFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationFilter`, this.getoptions());
  }

  getLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationInfo?${queryParams}`, this.getoptions());
  }

  getLiquidationPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/GetLiquidationPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetLiquidationPage.api, MENUACTIONROLEAPI.GetLiquidationPage.menu));
  }

  getOrderIntMethods(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetOrderIntMethods?${queryParams}`, this.getoptions());
  }

  delLiquidation(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/liquidation/DelLiquidation?${queryParams}`, this.getoptions());
  }

  setLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/liquidation/SetLiquidationInfo`, queryParams, this.getoptions());
  }

  setLiquidationDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationDraft`, queryParams, this.getoptions());
  }

  setLiquidationSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationSet`, queryParams, this.getoptions());
  }

  setLiquidationChange(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationChange`, queryParams, this.getoptions());
  }

  // UInvAgent

  getOrderAgentPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderagent/GetOrderAgentPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetOrderAgentPage.api, MENUACTIONROLEAPI.GetOrderAgentPage.menu));
  }

  setOrderAgentCommit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetOrderAgentCommit`, queryParams, this.getoptions());
  }

  setPayOnHalfOfAgent(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetPayOnHalfOfAgent`, queryParams, this.getoptions());
  }



  //UInvScheme

  getSchemePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemePage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetSchemePage.api, MENUACTIONROLEAPI.GetSchemePage.menu));
  }

  getSchemeFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeFilter`, this.getoptions());
  }

  getSchemeInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeInfo?${queryParams}`, this.getoptions());
  }

  getSchemeList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeList`, this.getoptions());
  }

  getSchemeTenor(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenor?${queryParams}`, this.getoptions());
  }

  getSchemeTenorList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenorList?${queryParams}`, this.getoptions());
  }

  getOpenTenorList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openscheme/GetOpenTenorList?${queryParams}`, this.getoptions());
  }

  getConvertPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetConvertPage.api, MENUACTIONROLEAPI.GetConvertPage.menu));
  }

  getConvertFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertFilter`, this.getoptions());
  }

  getConvertInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertInfo?${queryParams}`, this.getoptions());
  }

  getOpenTenorPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openscheme/GetOpenTenorPage?${queryParams}`, this.getoptions());
  }

  getOpenDiscountPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openconvert/GetOpenDiscountPage?${queryParams}`, this.getoptions());
  }

  getOpenDiscount(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/openconvert/GetOpenDiscount?${queryParams}`, this.getoptions());
  }

  getConvertList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertList?channel=UMEE`, this.getoptions());
  }

  getConvertDiscount(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemeconvert/GetConvertDiscount?${queryParams}`, this.getoptions());
  }

  delSchemeInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeInfo?${queryParams}`, this.getoptions());
  }

  setSchemeStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetSchemeStatus`, queryParams, this.options);
  }

  setConvertStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertStatus`, queryParams, this.options);
  }

  delSchemeTenor(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeTenor?${queryParams}`, this.getoptions());
  }

  delConvertInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemeconvert/DelConvertInfo?${queryParams}`, this.getoptions());
  }

  delConvertDiscount(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemeconvert/DelConvertDiscount?${queryParams}`, this.getoptions());
  }

  setConvertDiscount(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertDiscount`, queryParams, this.getoptions());
  }

  setConvertSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/setConvertSubmit`, queryParams, this.getoptions());
  }

  setSchemeInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeInfo`, queryParams, this.getoptions());
  }

  setConvertInfo(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemeconvert/SetConvertInfo`, queryParams, this.getoptions());
  }

  setSchemeTenor(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeTenor`, queryParams, this.getoptions());
  }

  setSchemeSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetSchemeSubmit`, queryParams, this.getoptions());
  }

  getSchemeTenorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/scheme/GetSchemeTenorPage?` + queryParams, this.getoptions());
  }

  getConvertDiscountPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/schemeconvert/GetConvertDiscountPage?` + queryParams, this.getoptions());
  }

  //UInvSchemeVoucher

  getSchemeVoucherFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherFilter`
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  getSchemeVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  getSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherInfo?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  setSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherInfo`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  setSchemeVoucherSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherSubmit`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  setSchemeVoucherStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherStatus`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  delSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemevoucher/DelSchemeVoucherInfo?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  getSchemeVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherList?` + queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

 

  // UInvAgency

  getAgencyPolicyPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetAgencyPolicyPage.api, MENUACTIONROLEAPI.GetAgencyPolicyPage.menu));
  }

  getAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyInfo?${queryParams}`, this.getoptions());
  }

  getAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyShare?${queryParams}`, this.getoptions());
  }

  getAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyConvert?${queryParams}`, this.getoptions());
  }

  getAgencyPolicyList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyList`, this.getoptions());
  }

  setAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyInfo`, queryParams, this.getoptions());
  }

  setAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyConvert`, queryParams, this.getoptions());
  }

  setAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyShare`, queryParams, this.getoptions());
  }

  rejectAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/RejectAgencyPolicyInfo`, queryParams, this.getoptions());
  }

  delAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyInfo?${queryParams}`, this.getoptions());
  }

  delAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyConvert?${queryParams}`, this.getoptions());
  }

  delAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyShare?${queryParams}`, this.getoptions());
  }

  // ksInvConvert

  getOpenConvertPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetOpenConvertPage?` + queryParams, this.getoptions());
  }

  // UmeUser

  getUserPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.getCustPage.api, MENUACTIONROLEAPI.getCustPage.menu));
  }

  getUserProfileByIdcard(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserProfileByIdcard?${queryParams}`, this.getoptions());
  }

  getUserInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserInfo?${queryParams}`, this.getoptions());
  }

  getUserProfile(): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/GetUserProfile`, this.getoptions());
  }

  getCustPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetCustPage?${queryParams}`, this.options);
  }

  getCustInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetCustInfo?${queryParams}`, this.options);
  }

  setUserProfile(queryParams): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/setUserProfile`, queryParams, this.getoptions());
  }


  //UInvAccount


  getAccountPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetAccountPage.api, MENUACTIONROLEAPI.GetAccountPage.menu));
  }

  getBankList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetBankList`, this.getoptions());
  }

  getAccountInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountInfo?${queryParams}`, this.getoptions());
  }

  delAccountInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/account/DelAccountInfo?${queryParams}`, this.getoptions());
  }

  setAccountInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountInfo`, queryParams, this.getoptions());
  }

  setAccountStatus(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountStatus`, queryParams, this.getoptions());
  }

  //API GETINFO
  apiGetInfo(manhinh, url, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/${url}?` + queryParams, this.getoptions());
    } else {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/${url}?` + queryParams, this.getoptions());
    }
  }

  apiSetInfo(manhinh, url, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/${url}`, queryParams, this.getoptions());
    } else {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/product/${url}`, queryParams, this.getoptions());
    }
  }

  getRoomList(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/openroom/GetRoomList?` + queryParams, this.getoptions())
    } else {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetRoomList?` + queryParams, this.getoptions())
    }
  }

  delOpenRoom(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/openroom/DelOpenRoom?` + queryParams, this.getoptions())
    } else {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/product/DelProdRoom?` + queryParams, this.getoptions())
    }
  }

  setOpenRoomStatus(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomStatus`, queryParams, this.getoptions())
    } else {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomStatus`, queryParams, this.getoptions())
    }
  }

  setOpenRoomStatusOpen(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomStatus`, queryParams, this.getoptions())
  }

  setOpenRoomSaled(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomSaled`, queryParams, this.getoptions())
  }

  setOpenRoomAdds(manhinh, queryParams): Observable<any> {
    if (manhinh === 'open') {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/openroom/SetOpenRoomAdds`, queryParams, this.getoptions())
    } else {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomAdds`, queryParams, this.getoptions())
    }
  }

  getProdRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProdRoomPage?` + queryParams, this.getoptions());
  }

  // UInvReport

  getReportList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/report/GetReportList?${queryParams}`, this.getoptions());
  }

  getTemplateReportPage(queryParams: string, url: string): Observable<any> {
    return this.http.post<any>(`${url}`, queryParams, this.getoptions())
  }

  // UInvFinal

  getFinalFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetFinalFilter`, this.getoptions());
  }

  getOrderFinalPage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/GetOrderFinalPage`, queryParams
    , this.getoptions(MENUACTIONROLEAPI.GetOrderFinalPage.api, MENUACTIONROLEAPI.GetOrderFinalPage.menu));
  }

  getOrderFinalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderFinalInfo?${queryParams}`, this.getoptions());
  }

  getOrderHistoryPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderHistoryPage?${queryParams}`
    , this.getoptions(MENUACTIONROLEAPI.GetOrderHistoryPage.api, MENUACTIONROLEAPI.GetOrderHistoryPage.menu));
  }

  setOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetOrderFinalPlan`, queryParams, this.getoptions());
  }

  setPayOnHalfOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnHalfOfFinal`, queryParams, this.getoptions());
  }

  setPayOnRollOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnRollOfFinal`, queryParams, this.getoptions());
  }

  delOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/final/DelOrderFinalPlan?` + queryParams , this.options);
  }

  // api Upload S3

  uploadFileS3(data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token-s3'))}`,
        'accept': 'application/json',
      })
    };
    return this.http.post(`${apiUploadS3Url}/upload`, data, options);
  }
  
  // CustRep
  getCustRepPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/cooperator/GetCustRepPage?${queryParams}`
    , this.options);
  }

  getCustRepInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/cooperator/GetCustRepInfo?${queryParams}`, this.options);
  }

  setCustRepInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/cooperator/SetCustRepInfo`, queryParams, this.options);
  }

  delCustRepInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/cooperator/DelCustRepInfo?${queryParams}`, this.options);
  }

  setCustRepStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/cooperator/SetCustRepStatus`, queryParams, this.options);
  }

  // UInvConvertBond

  getConvertBondPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/convertbond/GetConvertBondPage?${queryParams}`
    , this.options);
  }

  getConvertBondInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/convertbond/GetConvertBondInfo?${queryParams}`, this.options);
  }

  getKssBondUser(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/convertbond/GetKssBondUser?${queryParams}`, this.options);
  }

  setKssBond(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/convertbond/setKssBond`, queryParams, this.options);
  }

  getConvertTransPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/convertbond/GetConvertTransPage?${queryParams}`, this.options);
  }

  getOpenContList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/convertbond/GetOpenContList`, this.options);
  }

  delConvertBondInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/convertbond/DelConvertBondInfo?${queryParams}`, this.options);
  }

  setConvertTransCancel(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/convertbond/SetConvertTransCancel?${queryParams}`, this.options);
  }

  delConvertTransaction(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/convertbond/DelConvertTransaction?${queryParams}`, this.options);
  }

  setConvertBondInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/convertbond/SetConvertBondInfo`, queryParams, this.options);
  }

  setConvertBondStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/convertbond/SetConvertBondStatus`, queryParams, this.options);
  }

  setConvertTransApprove(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/convertbond/SetConvertTransApprove`, queryParams, this.options);
  }

  setConvertPayOnHalfApprove(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/convertbond/SetConvertPayOnHalfApprove`, queryParams, this.options);
  }


}

